import React from "react";
import Field from "./field.js";

const Phone = (props) => {
  const validationFunction = (value) => {
    if (value.trim() === "") {
      return false;
    }
    const regex = /^\+?(\(?\d+[. -]?\)?)+$/;
    return regex.test(value);
  };
  return (<Field {...props} type="tel" validationFunction={ validationFunction } />);
};

export default Phone;
